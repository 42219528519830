
import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import moment from "moment";

type Task = {
  id?: number;
  date_from?: string;
  time_from?: string;
  time_to?: string;
  notes?: string;
};

type Order = {
  id: number;
  order: string;
  customer: string;
  service: string;
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    datepicker_: () =>
      import("@/app/modules/_global/components/elements/DatePicker.vue"),
    timepicker_: () =>
      import("@/app/modules/_global/components/elements/TimePicker.vue")
  },
  filters: {
    taskDuration(value, t) {
      if (!value) return `0${t} 0m`;

      const h = ((value * 60) / 60) | 0;
      const m = Math.round((value * 60) % 60);

      return `${h}${t} ${m}m`;
    }
  }
})
export default class CreateTaskForm extends Vue {
  @Model("input", { type: Object }) readonly task!: Task;
  @Prop(Boolean) readonly disabled?: boolean;
  @Prop(Object) readonly errors?: object;

  @Emit()
  input() {
    return this.formData;
  }

  @Watch("formData", { deep: true })
  onFormDataChanged() {
    this.input();
  }

  @Watch("errors", { deep: true })
  onErrorsChanged(errors) {
    delete errors.time_from;
    delete errors.time_to;

    this.localErrors = { ...errors };
  }

  valid: boolean = true as boolean;
  localErrors: object = {} as object;

  formData: Task = {};
  internalOrders: Order[] = [];

  rules = {
    required: Rules.required,
    date: Rules.date
  };

  dateMax = moment().format("YYYY-MM-DD");
  dateMin = "";

  constructor() {
    super();

    this.formData = { ...this.task };
    if (!this.task.date_from) this.formData["date_from"] = this.dateMax;
  }

  mounted() {
    moment.tz.setDefault();

    this.fetchRepetitiveOrders();
    this.setDateMin();
  }

  get duration() {
    this.formData["duration"] = moment
      .duration(
        moment(this.formData["time_to"], "hh:mm").diff(
          moment(this.formData["time_from"], "hh:mm")
        )
      )
      .asHours();

    return this.formData["duration"];
  }

  get selectedOrder() {
    return this.internalOrders.find(_ => _.id == this.formData["order_id"]);
  }

  fetchRepetitiveOrders() {
    this.$store.dispatch("tasks/fetchRepetitiveOrders").then(({ data }) => {
      this.internalOrders.push(...data.data);
    });
  }

  setDateMin() {
    const now = moment();
    const limitDate = moment([now.year(), now.month(), 5]);
    const isAfter = limitDate.isAfter(now);

    // Restriction: Concierge should create reports for the previous month to the 5th day of the current month
    this.dateMin = isAfter
      ? moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD")
      : moment()
          .startOf("month")
          .format("YYYY-MM-DD");
  }
}
