
import { Component, Vue } from "vue-property-decorator";
import CreateTaskForm from "@/app/modules/tasks/components/CreateTaskForm.vue";

type Task = {
  id?: number;
  date_from?: Date;
  time_from?: string;
  time_to?: string;
  notes?: string;
};

@Component({
  components: {
    CreateTaskForm
  }
})
export default class CreateTaskPage extends Vue {
  private task: Task = {};
  errors = null;

  finishDialog = false;

  finishTask(formData: object) {
    this.$store
      .dispatch("tasks/taskReport", formData)
      .then(() => {
        this.$router.back();
      })
      .catch(error => {
        this.finishDialog = false;
        this.errors = error.response.data.errors;
      });
  }
}
